import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigPoiDefFragment } from '@gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { AddressbaseFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhlModuleType } from '@models/WhlModuleType';

export default (
  widgetConfigRef: MaybeRefOrGetter<Nullable<RawWidgetConfigFragment>>
): Ref<AddressbaseFilter> => {
  const typeConfigRef = useWidgetTypeConfig(
    widgetConfigRef,
    WhlModuleType.POI
  ) as Ref<RawWidgetConfigPoiDefFragment>;

  return computed((): AddressbaseFilter => {
    const widgetConfig = toValue(widgetConfigRef);
    if (isNotEmpty(widgetConfig)) {
      return rawBuildPoiBaseFilter(widgetConfig, toValue(typeConfigRef));
    }
    return {};
  });
};
