import type { RawWidgetConfigFragment } from '../../gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigPoiDefFragment } from '../../gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { AddressbaseFilter, IntArrayPredicate } from '../../gql/schema';
import { ImxPlatformModules } from '../../models/ImxPlatformModules';
import rawIsEmpty from './rawIsEmpty';
import rawIsNotEmpty from './rawIsNotEmpty';
import rawMapToIds from './rawMapToIds';

export default (
  widgetConfig: RawWidgetConfigFragment,
  typeConfig: RawWidgetConfigPoiDefFragment
): AddressbaseFilter => {
  if (rawIsEmpty(widgetConfig)) return {};

  const baseFilter: AddressbaseFilter = {
    and: [{ productlines: { noneOf: [ImxPlatformModules.TOUR] } }],
  };

  // client restriction
  if ((widgetConfig?.client?.id ?? 0) > 0) {
    baseFilter.client = {
      id: { oneOf: [widgetConfig.client?.id ?? -1] },
    };

    if (widgetConfig.includeSubClients === true) {
      baseFilter.client.includeSubClients = true;
    }
  }

  if (typeConfig) {
    const productlinePredicate: IntArrayPredicate = {};
    // productline (and)
    if (rawIsNotEmpty(typeConfig.productlinesAnd)) {
      productlinePredicate.allOf = rawMapToIds(typeConfig.productlinesAnd!);
    }

    // productline (or)
    if (rawIsNotEmpty(typeConfig.productlinesOr)) {
      productlinePredicate.oneOf = rawMapToIds(typeConfig.productlinesOr!);
    }

    // productline (not)
    if (rawIsNotEmpty(typeConfig.productlinesIgnore)) {
      productlinePredicate.noneOf = rawMapToIds(typeConfig.productlinesIgnore!);
    }

    if (rawIsNotEmpty(productlinePredicate)) {
      baseFilter.productlines = productlinePredicate;
    }

    // addressbases
    if (rawIsNotEmpty(typeConfig.addressbases)) {
      baseFilter.id = {
        oneOf: rawMapToIds(typeConfig.addressbases!),
      };
    }

    // regions
    if (rawIsNotEmpty(typeConfig.regions)) {
      baseFilter.regions = { oneOf: rawMapToIds(typeConfig.regions!) };
    }

    // location
    if (rawIsNotEmpty(typeConfig.locations)) {
      baseFilter.location = { oneOf: rawMapToIds(typeConfig.locations!) };
    }

    // zipcodes
    if (rawIsNotEmpty(typeConfig.zipcodes)) {
      const zipCodes = (typeConfig.zipcodes ?? '').split(',');
      if (zipCodes.length > 0) {
        baseFilter.zipcode = { oneOf: zipCodes };
      }
    }

    // coordinates
    if (
      typeConfig.coordinates &&
      typeConfig.coordinates.latitude &&
      typeConfig.coordinates.latitude != 0 &&
      typeConfig.coordinates.longitude &&
      typeConfig.coordinates.longitude != 0 &&
      typeConfig.radius &&
      typeConfig.radius > 0
    ) {
      baseFilter.geoFilter = {
        distanceFromPoint: {
          point: {
            latitude: typeConfig.coordinates.latitude as number,
            longitude: typeConfig.coordinates.longitude as number,
          },
          maxDistance: typeConfig.radius as number,
        },
      };
    }
  }

  return baseFilter;
};
